<div class="row no-padding responsive">
    <div class="col">
        <div class="content">
<h2>Chi siamo?</h2>
<p>Just Play Bologna è un'<b>associazione di promozione sociale senza scopo di lucro affiliata ad Arci</b> animata da volontarie e volontari con la passione per i giochi da tavolo e di ruolo. </p>
<h2>Dove si trova la Ludoteca? </h2>
<p>La nostra Ludoteca si trova in <b>via Bentini 20 a Bologna</b>, al primo piano della Casa del Popolo "Bruno Tosarelli" presso i locali del Circolo Arci Brecht. Potete raggiungerci in auto, in autobus con le linee 27A, 97 e 98 (fermata "Corticella") o in treno scendendo alla stazione Bologna Corticella.  </p>

<h2>Quali sono le nostre attività? </h2>
<p>Oltre agli eventi che potete scoprire di volta in volta sui nostri canali informativi, ogni settimana organizziamo una serie di appuntamenti fissi: </p>
<ul>
    <li><b>Lunedì alle 21,00</b> Giochi da tavolo in Ludoteca;</li>
    <li><b>Mercoledì alle 20,30</b> Giochi da tavolo;</li>
    <li><b>Mercoledì alle 21,00</b> Giochi di ruolo;</li>
    <li><b>Sabato alle 15.00</b> Scacchi.</li>
</ul>
<p>L'ultimo giovedì di ogni mese giochiamo a subbuteo.</p>

<h2>Come fare per partecipare ai nostri appuntamenti? </h2>
<p>Per partecipare alla serate in Ludoteca <b>è necessario essere soci Arci</b>. </p>
<p>La <b>tessera costa 10 euro</b> ed è valida dal 1 ottobre di un anno al 30 settembre di quello successivo e si può sottoscrivere direttamente in sede il giorno stesso. </p>
<p>Per i <b>minori di 16 anni</b> esiste una tessera ridotta al costo di <b>8 euro</b>.  </p>
<p>Con l'iscrizione, il socio acquisisce la necessaria copertura assicurativa e può usufruire di tutto il circuito Arci nazionale. </p>

<h2>E' necessario prenotare? </h2>
<p><b>Non serve prenotare</b> per partecipare alle nostre serate di gioco, ma è sicuramente utile segnalare la propria presenza attraverso i nostri canali di contatto, così potremo preparare i giochi più adatti a voi. </p>
<p>Fatelo soprattutto se siete in un gruppo numeroso e/o desiderate partecipare in particolare a uno dei giochi che vengono di volta in volta proposti, per riservare il proprio posto al tavolo. </p>
<p>In ogni caso durante ogni serata sono presenti vari giochi a cui è sempre possibile aggregarsi, avendo cura di presentarsi con puntualità. </p>


<h2>E' possibile proporre giochi propri?</h2>
<p>Alle nostre serate di gioco è sempre possibile sia aggregarsi ai titoli proposti dalla nostra associazione che proporre i propri, oppure ancora utilizzare quelli della nostra collezione (per le serate che si svolgono in Ludoteca). </p>

<h2>Come vengono organizzati i tavoli?</h2>
<p>Durante ogni serata noi proponiamo e spieghiamo un gioco, ma anche altri partecipanti fanno lo stesso ed è possibile aggregarsi liberamente all'uno o agli altri. </p>
<p>Per conoscere i giochi di serata è utile iscriversi ai canali Telegram <a href="https://t.me/justplaybologna" target="_blank">Just Play Bologna</a> per la serata del lunedì e <a href="https://t.me/JustPlayBolognaGameNight" target="_blank">Just Play Bologna - Gruppo di Gioco</a> per quella del mercoledì. </p>

<h2>Bisogna conoscere le regole dei giochi proposti? </h2>
<p>Ogni nostra proposta di serata <b>comprende sempre anche la spiegazione delle regole</b>. </p>

<h2>Come fare per contattarci? </h2>
<p>Potete <b>commentare i nostri post</b> sui vari canali social, oppure <b>inviarci una mail all'indirizzo <a href="mailto:justplaybolo@gmail.com">justplaybolo@gmail.com</a></b>. </p>

<h2>Dove siamo sui social? </h2>
<p>Facebook <a href="https://www.facebook.com/justplaybologna/" target="_blank">https://www.facebook.com/justplaybologna/</a></p>
<p>Instagram <a href="https://www.instagram.com/justplaybologna/" target="_blank">https://www.instagram.com/justplaybologna/</a></p> 
<p>Telegram <a href="https://t.me/justplaybologna" target="_blank">https://t.me/justplaybologna</a> - <a href="https://t.me/JustPlayBolognaGameNight" target="_blank">https://t.me/JustPlayBolognaGameNight</a></p>
</div>
</div>
</div>




